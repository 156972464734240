import { makeStyles } from '@material-ui/core/styles'

const cardWidth = "16.25rem" // 260px
const cardHeight = "18.75rem" // 300px
const cardPadding = "1rem" // 16px
const useStyles = makeStyles( theme => ({
    root:{
        display:'flex',
        flexDirection:'column',
        // This will make card has fix width
        // justifyContent:'center',
        // minWidth: cardWidth,
        // maxWidth:cardWidth,
        minHeight:cardHeight,
        maxHeight:cardHeight,
        boxShadow:'0px 9px 18px rgba(0, 0, 0, 0.06)',
        maxWidth:cardWidth
    },
    cardDetail:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        padding: cardPadding,
        minHeight:'45%',

    },
    
}))
export default useStyles