import { makeStyles } from '@material-ui/core/styles'

const modalDialogTitleHeight = "4rem" // 64px
const modalContentPadding = "3.5rem" // 56pxc
// const imageWidth = "21.875rem" // 350px
const imageWidth = "95%" // 350px
const useStyles = makeStyles( theme => ({
    modalRoot:{
        
        '& .MuiDialog-container':{
            '& .MuiPaper-root':{
                minWidth:'60%',

            }
        }
    },
    modalDialogTitle:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-end',
        maxHeight:modalDialogTitleHeight,
    },
    modalDialogContent:{
        paddingLeft:modalContentPadding,
        paddingRight:modalContentPadding,
        paddingBottom:modalDialogTitleHeight,
    },
    modalProductImg:{
        minWidth:imageWidth,
        maxWidth:imageWidth,
    },
    cardButtonModal:{
        padding:0,
        textTransform:'capitalize',
        maxWidth:'100%',
        '&:hover':{
            background:'transparent',
        },
        '& .MuiButton-label':{
            justifyContent:'flex-start',
            color:theme.palette.primary.main,
            '&:hover':{
                color:'#000'
            }
        }
    }
}))
export default useStyles