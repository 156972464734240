import React from "react"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "gatsby"
import Wrapper from "./Wrapper"
import Headline from "./Headline"
import Navbar from "./Navbar"
import SEO from "./seo"
import Footer from "./Footer"
import Banner from "./Banner"
import VissionMission from "./VisionMission"
import BestSeller from "./BestSeller"
import ContactContent from "./ContactContent"
import ContactForm from "./ContactForm"
import AboutUs from './AboutUs'

import inqBackground from "../images/inquiry-bg.png"

const useStyles = makeStyles( theme => ({
  sectionVissionMission:{
    padding:"6.25rem 0",
    [theme.breakpoints.down("md")]: {
      padding:"3rem 0"
    }
  },
  sectionAboutUs:{
    [theme.breakpoints.down("md")]: {
      padding:'3rem'

    }
  },
  sectionBestSeller:{
    [theme.breakpoints.down("md")]: {
      padding:'3rem'
    }
  },
  sectionSendInquiry:{
    [theme.breakpoints.down("md")]: {
      // padding:'3rem'
    }
  },
  greenOverlay:{
    width:"100%",
    [theme.breakpoints.down("md")]: {
      padding:'3rem 2rem',
      maxWidth:'100%'
    }
  },
  contactFormWrapper:{
    padding:0,
    [theme.breakpoints.down("md")]: {
      marginBottom:'50px'
    }
  },
  sendInquiryWrapper:{
    [theme.breakpoints.down("md")]: {
      flexDirection:'column',
      padding:'1rem'

    }
  }
}))

const RenderHTML= content =>{
  return(
    <Box dangerouslySetInnerHTML={ {__html: content} } />
  )
}


const IndexPage = ({ pageContext }) => {
  const classes = useStyles()
  const { dataPage, productBest, footer } = pageContext
  console.log(footer)
  let listProduct = []
  if (productBest && productBest.length != 0 ){
    productBest.map(prod => {
      const obj = {
        name: prod.namaBarang,
        images: prod.gambarProduct.url,
        subCategory: prod.subCategory.namaSubKategori,
        description: '',
        content: RenderHTML(prod.deskripsi),
      }
      listProduct.push(obj)
    })
  }
  
  return (
    <Wrapper whatsappNumber={footer.whatsappNumber}>
      <SEO title="Home" />
      <Headline />
      <Navbar />
      <Banner urlBanner={dataPage.banner.url} headingBanner={dataPage.headingBanner} deskripsiBanner={dataPage.deskripsiBanner} textButton={dataPage.textButtonBanner} linkButton={dataPage.linkButtonBanner} />

      {/* Vission Mission Section */}
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        className={classes.sectionVissionMission}
      >
        <VissionMission headingVisionMission={dataPage.headingVisionMission} deskripsi={dataPage.deskripsiVisionMission} />
      </Box>

      {/* About Us Section */}
      <Box
        display="flex"
        width="100%"
        minHeight="80vh"
        padding="0 0 6.25rem 9.375rem"
        className={classes.sectionAboutUs}
      >
        <AboutUs headingAboutUs={dataPage.headingAboutUs} deskripsi={dataPage.deskripsiAboutUs} thumbnail={dataPage.thumbnailVideo.url} linkVideo={dataPage.linkVideo} />
      </Box>

      {/* Best Seller Section */}
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        paddingY="6.25rem"
        bgcolor="#F3FAF6"
        className={classes.sectionBestSeller}
      >
        <BestSeller headingBestSeller={dataPage.headingBestSeller} deskripsi={dataPage.deskripsiBestSeller} productBestSeller={listProduct} />
      </Box>

      {/* Send Inquiry Section */}
      <Box
        width="100%"
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        style={{
          background: `url(${inqBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className={classes.sectionSendInquiry}
      >
        <Box
          paddingY="6.25rem"
          paddingX="9.375rem"
          display="flex"
          justifyContent="center"
          style={{ backgroundColor: " #189A4A80" }}
          className={classes.greenOverlay}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            borderRadius="5px"
            bgcolor="#FFF"
            className={classes.sendInquiryWrapper}
          >
            <Box className={classes.contactFormWrapper} paddingY="80px" paddingX="100px">
              <ContactContent
                heading={dataPage.headingInquiry}
                caption={dataPage.deskripsiInquiry}
              >
                <Link to="/contact" style={{ textDecoration: "none", color: "#189A4A" }}>
                  <Typography variant="body1">Contact Us</Typography> 
                </Link>
              </ContactContent>
            </Box>
            <ContactForm emailPenerima={pageContext.footer.emailPenerima} />
          </Box>
        </Box>
      </Box>

      <Footer data={pageContext.footer} />
    </Wrapper>)
  }

export default IndexPage
