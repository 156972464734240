import React from 'react'
import { Box, Typography } from '@material-ui/core'

import useStyles from './style'
const VisionMission = (props) => {
    const classes = useStyles()
    const { headingVisionMission, deskripsi } = props
    return (
        <Box className={classes.root} maxWidth="80%" minWidth="80%">
            <Box 
                display="flex"
                flexDirection="column"
                padding="5rem"
                className={classes.boxWrapper}
                minWidth="100%"
            >
                <Box className={classes.overline} mb="8px" lineHeight="16.8px">
                    <Typography variant="overline" className={classes.textOverline}>
                        Our Vision and Mission
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" className={classes.greenWrapper}>
                    <Box className={classes.leftSide}>
                        <Box maxWidth="27.625rem">
                            <Typography variant="h2" className={classes.heading}>
                                {headingVisionMission}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.rightSide}>
                        <Box maxWidth="27.625rem" lineHeight="24px" style={{opacity:.8}}>
                            <div dangerouslySetInnerHTML={ {__html: deskripsi} } />
                            {/* <Typography variant="body2" dangerouslySetInnerHTML={ {__html: deskripsi} }>
                            </Typography> */}
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>
    )
}

export default VisionMission
