import React, { Fragment } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  Grid,
  Box,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { useTheme } from "@material-ui/core/styles"
import useStyles from "./style"

const ProductModal = props => {
  const classes = useStyles()
  const {
    handleModal,
    modalStatus,
    productName,
    productDesc,
    productSubCategory,
    productContent,
    productImages,
  } = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Fragment>
      <Button
        className={classes.cardButtonModal}
        disableElevation
        onClick={handleModal}
      >
        <Typography variant="body1">View Detail</Typography>
      </Button>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleModal}
        aria-labelledby="customized-dialog-title"
        open={modalStatus}
        className={classes.modalRoot}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.modalDialogTitle}
          onClose={handleModal}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.modalDialogContent}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <img
                src={productImages}
                alt="product-img"
                className={classes.modalProductImg}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Box mb=".5rem">
                <Typography variant="overline">{productSubCategory}</Typography>
              </Box>
              <Box mb=".5rem">
                <Typography variant="h2">{productName}</Typography>
              </Box>
              <Box mb="1rem">
                <Typography variant="body2">{productDesc}</Typography>
              </Box>
              <Box>
                <Typography variant="body2">{productContent}</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default ProductModal
