import React from 'react'
import { Link } from "gatsby"
import { Box, Typography, Button } from '@material-ui/core'
import useStyles from './style'

import homeBanner from '../../images/homeBanner.png'

const Banner = (props) => {
    const classes = useStyles();
    const { urlBanner, headingBanner, deskripsiBanner, textButton, linkButton } = props
    
    return (
        <Box 
            className={classes.root} 
            style={{background:`url(${(urlBanner!='' ? urlBanner : homeBanner)})`}}
        >
            <Box className={classes.bannerOverlay}>
                <Box className={classes.bannerContent}>
                    <Box mb="1rem">
                        <Typography variant="overline" className={classes.bannerOverline}>
                            Oils, Fats & Soap
                        </Typography>
                    </Box>
                    <Box mb="1rem" maxWidth="32.8rem" lineHeight="3.6rem" fontWeight="700">
                        <Typography variant="h1" className={classes.bannerHeading}>
                            {(headingBanner!='' ? headingBanner : "We are working with the best and high quality natural resources")}
                        </Typography>
                    </Box>
                    <Box mb="1.5rem" maxWidth="32.8rem" style={{opacity:.8}}>
                        <Typography variant="body2" className={classes.bannerDesc}>
                            {(deskripsiBanner!='' ? deskripsiBanner : "supported by high technology machine and engine to produce the best product to satisfy our costumers")}
                        </Typography>
                    </Box>
                    <Box maxWidth="170px">
                        <Link to={(linkButton!='' ? linkButton : "/products")} style={{ textDecoration: "none" }}>
                            <Button variant="contained" color="secondary" className={classes.bannerBtn}>{(textButton!='' ? textButton : "See Our Product")}</Button>
                        </Link>                        
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Banner
