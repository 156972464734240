import React from 'react'
import { Box, Typography } from '@material-ui/core'
import ProductList from '../ProductList'

import useStyles from './style'

import SampleImage from "../../images/sample-img.png"
import SampleImage2 from "../../images/sample-img-2.png"

const productsData = [
    {
      name: "La Bella Beauty Soap Satu ",
      images: SampleImage2,
      subCategory: "beauty soap",
      description:
        "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
      content: "- unavailable -",
    },
    {
      name: "La Bella Beauty Soap Dua",
      images: SampleImage2,
      subCategory: "beauty soap",
      description:
        "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
      content: "- unavailable -",
    },
    {
      name: "La Bella Beauty Soap Tiga",
      images: SampleImage2,
      subCategory: "beauty soap",
      description:
        "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
      content: "- isinya markup html dari cms -",
    },
    {
      name: "La Bella Beauty Soap Empat",
      images: SampleImage2,
      subCategory: "beauty soap",
      description:
        "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
      content: "- isinya markup html dari cms -",
    },
    {
      name: "La Bella Beauty Soap 150/125gr",
      images: SampleImage2,
      subCategory: "beauty soap",
      description:
        "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
      content: "- isinya markup html dari cms -",
    },
    {
        name: "La Bella Beauty Soap 150/125gr",
        images: SampleImage2,
        subCategory: "beauty soap",
        description:
          "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
        content: "- isinya markup html dari cms -",
      },
      {
        name: "La Bella Beauty Soap 150/125gr",
        images: SampleImage2,
        subCategory: "beauty soap",
        description:
          "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
        content: "- isinya markup html dari cms -",
      },
      {
        name: "La Bella Beauty Soap 150/125gr",
        images: SampleImage2,
        subCategory: "beauty soap",
        description:
          "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
        content: "- isinya markup html dari cms -",
      },
      {
        name: "La Bella Beauty Soap 150/125gr",
        images: SampleImage2,
        subCategory: "beauty soap",
        description:
          "LaBella beauty soaps are specially formulated with extra glycerine and pure natural extracts, it allows the pampering of skin with exotic scents of natural oils, leaving the skin enriched with moisture and sensuous fragnances",
        content: "- isinya markup html dari cms -",
      },
  ]

const BestSeller = (props) => {
    const classes = useStyles()
    const { headingBestSeller, deskripsi, productBestSeller } = props
    return (
        <Box className={classes.root}>
            <Box className={classes.textHeading} display="flex" flexDirection="column" alignItems="center" mb="3.25rem">
                <Box mb="8px">
                    <Typography variant="h2" className={classes.heading}> 
                        {headingBestSeller}
                    </Typography>
                </Box>
                <Box maxWidth="28rem" style={{opacity:.7}} textAlign="center">
                    <Typography variant="body2">
                        {deskripsi}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.bestSellerProductWrapper} display="flex" justifyContent="center">
                <ProductList products={productBestSeller}/>
            </Box>
        </Box>
    )
}

export default BestSeller
