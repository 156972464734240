import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles( theme => ({
    root:{
        minWidth:"100%",
        display:'flex',
        justifyContent:'space-between',
        [theme.breakpoints.down("md")]: {
            flexDirection:'column'
        }
    },
    leftSide:{
        // [theme.breakpoints.between("md","lg")]: {
        //     minWidth:'50%',
        //     maxWidth:'50%',
        //     background:'red'
        // },
        [theme.breakpoints.down("md")]: {
            minWidth:'100%',
        }
    },
    rightSide:{
        // [theme.breakpoints.between("md","lg")]: {
        //     minWidth:'50%',
        //     maxWidth:'50%'
        // },
        [theme.breakpoints.down("md")]: {
            minWidth:'100%',
        }
    },
    videoBGWrapper:{
        maxWidth:"611px",
        minWidth:"611px",
        minHeight:"560px",
        maxHeight:"560px",
        [theme.breakpoints.down("md")]: {
            minWidth:'100%',
            maxWidth:'100%',
            minHeight:'300px',
        }
    },
    headingWrapper:{
        [theme.breakpoints.between("sm","md")]: {
            maxWidth:'100%',
            textAlign:'center'
        },

    },
    heading:{
        [theme.breakpoints.down("md")]: {
            fontSize:'30px'
        }
    },
    textOverlineWrapper:{
        [theme.breakpoints.between("sm","md")]: {
            maxWidth:'100%',
            textAlign:'center',
        },
    },
    textOverline:{
        [theme.breakpoints.down("md")]: {
            fontSize:'12px'
        }
    },
    textDescriptionWrapper:{
        [theme.breakpoints.between("sm","md")]: {
            maxWidth:'80%',
            textAlign:'center',
            margin:'auto'
        },
    },
    textDescription:{
        marginBottom:"50px"
    }
            

}))
export default useStyles