import { makeStyles } from '@material-ui/core/styles'

const bannerHeight = "40rem"
const bannerResponsiveHeight = "20rem"
const contentPadding = "9.375rem 9.375rem"
const useStyles = makeStyles( theme => ({
    // [theme.breakpoints.down('sm')]: {
    //     root:{
    //         maxWidth:'100%',
    //         maxHeight:'5vh'
    //         // background:'red'
    //     }
    // },
    root:{
        minHeight:bannerHeight,
        backgroundRepeat:'no-repeat !important',
        backgroundSize:'cover !important',
        [theme.breakpoints.down("md")]: {
            minHeight:bannerResponsiveHeight,
            // padding:"1rem"
        }
    },
    bannerOverlay:{
        background:'linear-gradient(to left, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.80) 100%)',
        // height:bannerHeight,
        [theme.breakpoints.down("md")]: {
            // height:bannerResponsiveHeight
        }
    },
    bannerContent:{
        display:'flex',
        flexDirection:'column',
        // alignItems:'center',
        padding:contentPadding,
        color:'#FFF',
        [theme.breakpoints.down("md")]: {
            // padding:'3.125rem 3.125rem'
            padding:'2rem'
        },
    },
    bannerOverline:{
        [theme.breakpoints.down("md")]: {
            fontSize:'10px'
        }
    },
    bannerHeading:{
        [theme.breakpoints.down("md")]: {
            fontSize:'35px'
        }
    },
    bannerDesc:{
        [theme.breakpoints.down("md")]: {
            fontSize:'15px'
        }
    },
    bannerBtn:{
        color:'#FFF',
        textTransform:'capitalize',
        borderRadius:'4.063rem',
        height:'3rem',
        minWidth:'100%',
        fontWeight:700,
    }
}))
export default useStyles