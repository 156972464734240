import React, { Fragment } from "react"
import { 
    Box, 
    Button, 
    Dialog, 
    DialogTitle,
    useMediaQuery,
    IconButton,
    DialogContent
} from "@material-ui/core"
import ReactPlayer from 'react-player/lazy'

import CloseIcon from "@material-ui/icons/Close"

import VideoPlayButton from '../../images/play-video-button-icon.svg'
import useStyles from "./style"

//https://youtu.be/Sy_Uay0fxlg

const VideoPlayerModal = props => {
  const { modalStatus, handleModal, youtubeLink } = props
  const classes = useStyles()

  return (
    <Fragment>
      {/* <Box className={classes.wrapper}> */}
        <Button
          className={classes.cardButtonModal}
          disableElevation
          onClick={handleModal}
        >
          <img src={VideoPlayButton} alt="video-play-button-icon"/>
        </Button>
      {/* </Box> */}
      <Dialog
        onClose={handleModal}
        aria-labelledby="customized-dialog-title"
        open={modalStatus}
        className={classes.modalRoot}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.modalDialogTitle}
          onClose={handleModal}
        >
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.modalDialogContent}>
          <ReactPlayer url={youtubeLink} width="100%" height="460px"/>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default VideoPlayerModal
