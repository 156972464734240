import React, { useState } from "react"
import { Box, Typography } from "@material-ui/core"

import VideoPlayerModal from "../VideoPlayerModal"
import useStyles from "./style"

import VideoThumbnail from "../../images/videoThumbnail.png"

const AboutUs = props => {
  const classes = useStyles()
  const { headingAboutUs, deskripsi, thumbnail, linkVideo } = props
  const [openModal, setOpenModal] = useState(false)
  const handleClickModal = () => setOpenModal(!openModal)
  return (
    <Box className={classes.root}>
      <Box className={classes.leftSide} maxWidth="50%" minWidth="50%">
        <Box mb="8px" className={classes.textOverlineWrapper}>
          <Typography variant="overline" className={classes.textOverline}>About Us</Typography>
        </Box>
        <Box mb="8px" maxWidth="33.75rem" className={classes.headingWrapper}>
          <Typography variant="h2" className={classes.heading}>{headingAboutUs}</Typography>
        </Box>
        <Box style={{ opacity: 0.7 }} maxWidth="34.5rem">
          <div dangerouslySetInnerHTML={ {__html: deskripsi} } />
          {/* <Typography variant="body2" dangerouslySetInnerHTML={ {__html: deskripsi} }>
          </Typography> */}
        </Box>
      </Box>
      <Box
        className={classes.rightSide}
        maxWidth="50%"
        minWidth="50%"
        display="flex"
        justifyContent="flex-end"
        // bgcolor="pink"
      >
        <Box
          className={classes.videoBGWrapper}
          style={{
            background: `url(${thumbnail})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            bgcolor="#00000070"
            minHeight="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            // bgcolor="purple"
          >
            <VideoPlayerModal
              modalStatus={openModal}
              handleModal={handleClickModal}
              youtubeLink={linkVideo}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AboutUs
