import React, { useState } from "react"
import { Box, Card, CardMedia, Typography, Button } from "@material-ui/core"

import ProductModal from "../ProductModal"
import useStyles from "./style"
const ProductListCard = props => {
  const classes = useStyles()

  const [openModal, setOpenModal] = useState(false)
  const handleClickModal = () => setOpenModal(!openModal)
  const { productData } = props

  return (
    <Card className={classes.root}>
      <CardMedia component="img" height="170" image={productData.images} />
      <Box className={classes.cardDetail}>
        <Typography variant="subtitle1" className={classes.cardTitle}>
          {productData.name}
        </Typography>

        <ProductModal
          handleModal={handleClickModal}
          modalStatus={openModal}
          // Product Data
          productImages={productData.images}
          productName={productData.name}
          productDesc={productData.description}
          productSubCategory={productData.subCategory}
          productContent={productData.content}
        />
      </Box>
    </Card>
  )
}

export default ProductListCard
