import { makeStyles } from '@material-ui/core/styles'

const modalDialogTitleHeight = "4rem" // 64px
const modalContentPadding = "3.375rem" // 56px
// const imageWidth = "21.875rem" // 350px
const modalWidth = "57rem" // 350px
const useStyles = makeStyles( theme => ({
    modalRoot:{
        '& .MuiDialog-container':{
            '& .MuiPaper-root':{
                minWidth:modalWidth,
                justifyContent:'center',
                [theme.breakpoints.down("md")]: {
                    minWidth:"100%"
                }

            }
        }
    },
    cardButtonModal:{
        // background:'red',
    },
    modalDialogTitle:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-end',
        maxHeight:modalDialogTitleHeight,
    },
    modalDialogContent:{
        paddingLeft:modalContentPadding,
        paddingRight:modalContentPadding,
        paddingBottom:modalDialogTitleHeight,
        [theme.breakpoints.down("md")]: {
            paddingLeft:20,
            paddingRight:20,
        }

    },
}))
export default useStyles