import { makeStyles } from '@material-ui/core/styles'

const vmBoxHeight = "24.063rem"

const useStyles = makeStyles( theme => ({
    root:{
        background:theme.palette.primary.main,
        display:'flex',
        borderRadius:'5px',
        minHeight:vmBoxHeight,
        color:"#FFF"
    },
    boxWrapper:{
        [theme.breakpoints.down("md")]: {
            padding:"2.5rem"
        }
    },
    overline:{
        textTransform:'uppercase',
    },
    greenWrapper:{
        [theme.breakpoints.down("md")]: {
            flexDirection:'column'
        }
    },
    heading:{
        [theme.breakpoints.down("md")]: {
            fontSize:'30px',
            marginBottom:'20px'
        }
    },

    description:{
        [theme.breakpoints.down("md")]: {
            fontSize:'15px'
        }
    },

    leftSide:{
        display:'flex',
        minWidth:'45.5%',
        maxWidth:'45.5%',
        [theme.breakpoints.down("md")]: {
            maxWidth:'100%',
            minWidth:'100%'
        }
    },
    rightSide:{
        display:'flex',
        minWidth:'45.5%',
        maxWidth:'45.5%',
        [theme.breakpoints.down("md")]: {
            maxWidth:'100%',
            minWidth:'100%'
        }
    },
    textOverline:{
        [theme.breakpoints.down("md")]: {
            fontSize:'12px'
        }
    }
}))
export default useStyles