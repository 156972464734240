import React from 'react'
import { Box, Grid } from '@material-ui/core'

import ProductListCard from '../ProductListCard'
import useStyles from './style'

const ProductList = props => {
    const classes = useStyles()
    const { products } = props

    // console.log('products', products)
    return (
        <Box className={classes.root}>
            <Grid container spacing={3}>
                {products.map( (product, index) =>
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className={classes.productItem} key={index}>
                        <ProductListCard productData={product} />
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default ProductList
