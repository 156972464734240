import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles( theme => ({
    root:{
        //
    },
    heading:{
        [theme.breakpoints.down("md")]: {
            fontSize:'30px',
            textAlign:'center',
            marginBottom:'10px'
        }
    }
}))
export default useStyles